








































































import { Component, Vue, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts/dist/vue-apexcharts";
import { CategoryType } from "@/models/category/CategoryType";
import CategorySelect from "@/components/category/CategorySelect.vue";
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import moment from 'moment';
import { RevenueReportService } from "@/views/dashboard/RevenueReportService";
import { CustomerGroupService } from "@/views/customer-group/CustomerGroupService";
import { TreeModel } from "@/components/tree/TreeModel";
import groupBy from 'lodash/groupBy';
import sumBy from 'lodash/sumBy';
import vSelect from 'vue-select';

@Component({
    components: {
        apexchart: VueApexCharts,
        CategorySelect,
        CustomerGroupTreeDropdown,
        UserSuggestion,
        'v-select': vSelect,
    }
})
export default class TopProduct extends Vue {
    data = null;
    filterBy = 0;
    filterValue = 0;
    year = 0;
    isShowFilter = false;
    isLoading = false;
    categoryType = CategoryType;
    salesChannelIds = [];
    resources = [];
    listUserSelected = [];
    customerSalesChannels: any[] = [];
    listYear: number[] = [];
    listFilterBy = [{id: 0, name: 'Tuần'},
        {id: 1, name: 'Tháng'},
        {id: 2, name: 'Quý'},
        {id: 3, name: 'Năm'}];

    series = [{
        name: '',
        data: []
    }];
    chartOptions = {
        chart: {
            type: 'bar',
            height: 430
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: [],
        },
    };

    @Watch('filterBy')
    onFilterByChange(value: any) {
        this.filterValue = value === 0 ? moment().week() + 1
            : value === 1 ? moment().month() + 1
                : value === 2 ? moment().quarter()
                    : 0;
    }

    @Watch('isLoading')
    onLoadingChange(value: any) {
        if (value) {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: '#btnTopProductSearch',
                scale: 0.45
            });
        } else {
            this.$vs.loading.close('#btnTopProductSearch > .con-vs-loading');
        }
    }

    get filterValueLabel() {
        return this.filterBy === 0 ? 'Tuần'
            : this.filterBy === 1 ? 'Tháng'
                : this.filterBy === 2 ? 'Quý'
                    : 'Năm';
    }

    get listFilterValue() {
        let filterValues: any = [];
        const currentYear = new Date().getFullYear();
        switch (this.filterBy) {
            case 0:
                for (let i = 1; i <= 54; i++) {
                    filterValues.push(i);
                }
                return filterValues;
            case 1:
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            case 2:
                return [1, 2, 3, 4];
            case 3:
                for (let i = 2018; i <= currentYear; i++) {
                    filterValues.push(i);
                }
                return filterValues;
            default:
                return [];
        }
    }

    beforeMount() {
        this.renderYear();
        const weekday = moment().weekday();
        this.filterValue = weekday === 0 ? moment().weeks() - 1 : moment().weeks();
    }

    mounted() {
        this.search();
        this.getCustomerGroup();
    }

    onSalesChannelSelected(data: any) {

    }

    onSelectResource(data: any) {
        this.resources = data;
    }

    onUserSelected(result: any) {
        this.listUserSelected = result;
    }

    onSelectedUserRemoved(item: any) {
        const index = this.listUserSelected.findIndex((user: any) => {
            return user.id === item.id;
        });
        this.$delete(this.listUserSelected, index);
    }

    search() {
        if (this.filterBy == null || this.filterBy === undefined) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn tần xuất`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
            return;
        }
        if (this.filterValue == null || this.filterValue === undefined && this.filterBy != 3) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn ${this.filterValueLabel}`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
        } else {
            this.isLoading = true;
            new RevenueReportService()
                .getTopProducts(10, {
                    filterBy: this.filterBy,
                    filterValue: this.filterValue,
                    year: this.year,
                    resourceId: this.resources.map((resource: any) => {
                        return resource.id;
                    }),
                    salesChannelId: this.salesChannelIds,
                    managerIds: this.listUserSelected.map((user: any) => {
                        return user.id;
                    }),
                    managerIdNumbers: this.listUserSelected.map((user: any) => {
                        return user.data.idNumber;
                    })
                })
                .then((result: any) => {
                    this.isLoading = false;
                    this.updateChartSeries(result);
                    this.updateChartOptions(result.data);
                })
                .catch(() => this.isLoading = false);
        }
    }

    private getCustomerGroup() {
        const customerGroupService = new CustomerGroupService();
        customerGroupService.search()
            .then((result: TreeModel[]) => {
                this.customerSalesChannels = result;
            });
    }

    private renderYear() {
        const currentYear = new Date().getFullYear();
        this.year = currentYear;
        for (let i = 2018; i <= currentYear; i++) {
            this.listYear.push(i);
        }
    }

    private updateChartSeries(result: any) {
        const data = result.data;
        const filterRanges = result.filterRanges;
        const seriesData = filterRanges.map((filterRange: any) => {
            const groupByName = groupBy(data, 'name');
            const groupData: any = [];
            for (let key in groupByName) {
                const group = groupByName[key];
                const dataByRange = group.filter((item: any) => {
                    return this.filterBy === 3 ? item.year === filterRange.year
                        : item.filterValue === filterRange.value && item.year === filterRange.year;
                });
                groupData.push(!dataByRange ? 0 : sumBy(dataByRange, 'totalAmount'))
            }
            return {
                name: this.getSerieName(this.filterBy === 3 ? filterRange.year : filterRange.value, filterRange.year),
                data: groupData
            }
        });
        const chart: any = this.$refs.topProductChart as any;
        chart.updateSeries(seriesData);
    }

    private updateChartOptions(data: any) {
        const groupByName = groupBy(data, 'name');
        const categoryNames: any = [];
        for (let key in groupByName) {
            categoryNames.push(key);
        }
        const chart: any = this.$refs.topProductChart as any;
        chart.updateOptions({
            xaxis: {
                categories: categoryNames,
            }
        });
    }

    private getSerieName(filterValue: number, year: number) {
        return `${this.filterBy === 0 ? 'Tuần' : this.filterBy === 1 ? 'Tháng' : this.filterBy === 2 ? 'Quý' : 'Năm'}
        ${filterValue} ${this.filterBy != null ? `Năm ${year}` : ''}`;
    }
}
