import axios from 'axios';
import { SearchResult } from '@/models/SearchResult';
import { UserSuggestionModel } from '@/components/suggestion/user-suggestion/UserSuggestionModel';

export interface ShortItem {
    id: string;
    name: string;
}

export interface UserConfigResult {
    id: string;
    userId: number;
    officeId: number | null;
    fullName: string;
    channelId: string;
    isChannelLeader: boolean;
    receiveSONotify: boolean;
    logisticManager: boolean;
    branches: ShortItem[];
    inventories: ShortItem[];
    isEditSaleChannel: boolean;
    isSaleAdmin: boolean;
    isUpdatePrice: boolean;
    isUpdateDiscount: boolean;
    maxDiscountPercent: number;
    maxDiscountValue: number;
    isUpdateOrder: boolean;
    isDeleteOrder: boolean;
    roleId: number | null;
    customerType: number;
}

export class UserService {
    url = `${process.env.VUE_APP_CORE_URL}api/v1/users`;

    async getConfig(userId: string): Promise<UserConfigResult | null> {
        const response = await axios.get(`${this.url}/${userId}/configs`);
        try {
            return response.data;
        } catch (e) {
            return null;
        }
    }

    suggestion(searchTerm: string, page = 1, pageSize = 20): Promise<SearchResult<UserSuggestionModel>> {
        return axios.get(`${this.url}/suggestions`, {
            params: {
                searchTerm, page, pageSize
            }
        })
    }
}