







import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {CustomerGroupService} from "@/views/customer-group/CustomerGroupService";
import {TreeModel} from "@/components/tree/TreeModel";

@Component({
    components: {
        Treeselect
    }
})
export default class CustomerGroupTreeDropdown extends Vue {
    @Prop() value!: any;
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: ''}) placeholder!: boolean;
    @Prop({default: () => []}) options: TreeModel[];

    listItem: TreeModel[] = [];

    @Watch('options')
    getOptions(value: TreeModel[]) {
        this.listItem = value;
    }

    mounted() {
    }

    updateValue(value: any) {
        this.$emit('input', value);
    }

    onTreeSelect() {
        this.$emit('input', this.value);
        setTimeout(() => {
            this.$emit('selected', this.value);
        }, 100);
    }

    async getAllTreeData() {
        const customerGroupService = new CustomerGroupService();
        try {
            this.listItem = await customerGroupService.search();
        } catch (e) {
            this.listItem = [];
        }
    }
}
