import axios from 'axios';
import {DashboardReportFilterRequest} from "@/views/dashboard/DashboardReportFilterRequest";

export class RevenueReportService {
    url = `${process.env.VUE_APP_GATEWAY_URL}order/api/v1/revenue-reports/`;

    getRevenueReportBySalesChannel(request: DashboardReportFilterRequest) {
        return axios.post(`${this.url}sales-channels`, request);
    }

    getRevenueReportByCustomer(request: DashboardReportFilterRequest) {
        return axios.post(`${this.url}customers`, request);
    }

    getRevenueReportByStaff(request: DashboardReportFilterRequest) {
        return axios.post(`${this.url}staffs`, request);
    }

    getRevenueReportByProductGroup(request: DashboardReportFilterRequest) {
        return axios.post(`${this.url}product-groups`, request);
    }

    getTopProducts(top: number, request: DashboardReportFilterRequest) {
        return axios.post(`${this.url}top-products/${top}`, request);
    }
}
