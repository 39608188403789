









































































import { Component, Vue, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts/dist/vue-apexcharts";
import CategorySelect from "@/components/category/CategorySelect.vue";
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import moment from 'moment';
import { CustomerGroupService } from "@/views/customer-group/CustomerGroupService";
import { TreeModel } from "@/components/tree/TreeModel";
import vSelect from 'vue-select';
import { CategoryType } from "@/models/category/CategoryType";
import { CallReportService } from "@/views/dashboard/CallReportService";

@Component({
    components: {
        apexchart: VueApexCharts,
        CategorySelect,
        CustomerGroupTreeDropdown,
        UserSuggestion,
        'v-select': vSelect,
    }
})
export default class CallReport extends Vue {
    data = null;
    filterBy = 0;
    filterValue = 0;
    year = 0;
    categoryType = CategoryType;
    isShowFilter = false;
    isLoading = false;
    salesChannelIds = [];
    resources = [];
    listUserSelected = [];
    customerSalesChannels: any[] = [];
    listYear: number[] = [];
    listFilterBy = [{id: 0, name: 'Tuần'},
        {id: 1, name: 'Tháng'},
        {id: 2, name: 'Quý'},
        {id: 3, name: 'Năm'}];
    series = [{
        name: 'Số lượng cuộc gọi đi',
        type: 'column',
        data: [1.4, 2, 2.5, 1.5, 2.5, 2.8, 3.8, 4.6]
    }, {
        name: 'Số lượng cuộc gọi nhận',
        type: 'column',
        data: [1.1, 3, 3.1, 4, 4.1, 4.9, 6.5, 8.5]
    }, {
        name: 'Thời lượng trung bình cuộc gọi đi',
        type: 'line',
        data: [20, 29, 37, 36, 44, 45, 50, 58]
    }];
    chartOptions = {
        chart: {
            height: 350,
            type: 'line',
            stacked: false
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            width: [1, 1, 4]
        },
        title: {
            text: '',
            align: 'left',
            offsetX: 110
        },
        xaxis: {
            categories: [],
        },
        yaxis: [
            {
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#008FFB'
                },
                labels: {
                    style: {
                        colors: '#008FFB',
                    }
                },
                title: {
                    text: "Số lượng cuộc gọi đi",
                    style: {
                        color: '#008FFB',
                    }
                },
                tooltip: {
                    enabled: true
                }
            },
            {
                seriesName: 'Số lượng cuộc gọi đi',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#00E396'
                },
                labels: {
                    style: {
                        colors: '#00E396',
                    }
                },
                title: {
                    text: "Số lượng cuộc gọi nhận",
                    style: {
                        color: '#00E396',
                    }
                },
            },
            {
                seriesName: 'Revenue',
                opposite: true,
                axisTicks: {
                    show: true,
                },
                axisBorder: {
                    show: true,
                    color: '#FEB019'
                },
                labels: {
                    style: {
                        colors: '#FEB019',
                    },
                },
                title: {
                    text: "Thời lượng trung bình/Cuộc gọi đi",
                    style: {
                        color: '#FEB019',
                    }
                }
            },
        ],
        tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft', // topRight, topLeft, bottomRight, bottomLeft
                offsetY: 30,
                offsetX: 60
            },
        },
        legend: {
            horizontalAlign: 'left',
            offsetX: 40
        }
    };

    @Watch('filterBy')
    onFilterByChange(value: any) {
        this.filterValue = value === 0 ? moment().week() + 1
            : value === 1 ? moment().month() + 1
                : value === 2 ? moment().quarter()
                    : 0;
    }

    @Watch('isLoading')
    onLoadingChange(value: any) {
        if (value) {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: '#btnCallReportSearch',
                scale: 0.45
            });
        } else {
            this.$vs.loading.close('#btnCallReportSearch > .con-vs-loading');
        }
    }

    get filterValueLabel() {
        return this.filterBy === 0 ? 'Tuần'
            : this.filterBy === 1 ? 'Tháng'
                : this.filterBy === 2 ? 'Quý'
                    : 'Năm';
    }

    get listFilterValue() {
        let filterValues: any = [];
        const currentYear = new Date().getFullYear();
        switch (this.filterBy) {
            case 0:
                for (let i = 1; i <= 54; i++) {
                    filterValues.push(i);
                }
                return filterValues;
            case 1:
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            case 2:
                return [1, 2, 3, 4];
            case 3:
                for (let i = 2018; i <= currentYear; i++) {
                    filterValues.push(i);
                }
                return filterValues;
            default:
                return [];
        }
    }

    beforeMount() {
        this.renderYear();
        const weekday = moment().weekday();
        this.filterValue = weekday === 0 ? moment().weeks() - 1 : moment().weeks();
    }

    mounted() {
        this.search();
        this.getCustomerGroup();
    }

    onSalesChannelSelected(data: any) {

    }

    onSelectResource(data: any) {
        this.resources = data;
    }

    onUserSelected(result: any) {
        this.listUserSelected = result;
    }

    onSelectedUserRemoved(item: any) {
        const index = this.listUserSelected.findIndex((user: any) => {
            return user.id === item.id;
        });
        this.$delete(this.listUserSelected, index);
    }

    search() {
        if (this.filterBy == null || this.filterBy === undefined) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn tần xuất`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
            return;
        }
        if (this.filterValue == null || this.filterValue === undefined && this.filterBy != 3) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn ${this.filterValueLabel}`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
        } else {
            new CallReportService()
                .getCallDurationReport({
                    filterBy: this.filterBy,
                    filterValue: this.filterValue,
                    year: this.year,
                    resourceId: this.resources.map((resource: any) => {
                        return resource.id;
                    }),
                    salesChannelId: this.salesChannelIds.map((salesChannel: any) => {
                        return salesChannel.id;
                    }),
                    managerIds: this.listUserSelected.map((user: any) => {
                        return user.id;
                    }),
                    managerIdNumbers: this.listUserSelected.map((user: any) => {
                        return user.data.idNumber;
                    })
                })
                .then((result: any) => {
                    // Cập nhật category
                    const chart: any = this.$refs.callLogDurationChart;
                    chart.updateOptions({
                        xaxis: {
                            categories: result.map((item: any) => {
                                return item.fullName
                            })
                        }
                    });

                    const callOut = result.map((item: any) => {
                        return item.callOut ? item.callOut : 0;
                    });

                    const callIn = result.map((item: any) => {
                        return item.callIn;
                    });

                    const avgCallOut = result.map((item: any) => {
                        return item.callOutDuration > 0 ? Math.ceil(item.callOutDuration / item.callOut) : 0
                    });

                    chart.updateSeries([
                        {name: 'Số lượng cuộc gọi đi', data: callOut},
                        {name: 'Số lượng cuộc gọi nhận', data: callIn},
                        {name: 'Thời lượng cuộc gọi trung bình/Cuộc gọi đi', data: avgCallOut},
                    ]);
                })
        }
    }

    private getCustomerGroup() {
        const customerGroupService = new CustomerGroupService();
        customerGroupService.search()
            .then((result: TreeModel[]) => {
                this.customerSalesChannels = result;
            });
    }

    private renderYear() {
        const currentYear = new Date().getFullYear();
        this.year = currentYear;
        for (let i = 2018; i <= currentYear; i++) {
            this.listYear.push(i);
        }
    }
}
