import {CategoryType} from "@/models/category/CategoryType";
import i18n from "@/i18n";

export class Category {
    id: string | undefined;
    name: string | undefined;
    description: string | undefined;
    type: number | null;
    objType: OptionItem | undefined;
    managerId : string | undefined;
    /// Tên người quản lý
    fullName : string | undefined;

    /// Ảnh người quản lý
    avatar: string | undefined;
    idNumber: number;

    constructor(id?: string, name?: string, description?: string, type?: OptionItem) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.objType = type;
    }
    //
    // get typeName() {
    //     switch (this.type) {
    //         case CategoryType.CustomerResources: {
    //             return "Nguồn khách hàng";
    //         }
    //         case CategoryType.CustomerArea: {
    //             return "Vùng khách hàng";
    //         }
    //         case CategoryType.CustomerClassify: {
    //             return "Loại khách hàng";
    //         }
    //         case CategoryType.CustomerPotentialLevel: {
    //             return "Mức độ tiềm năng";
    //         }
    //         case CategoryType.CustomerCompanySize: {
    //             return "Quy mô công ty";
    //         }
    //         case CategoryType.CustomerBusinessCareer: {
    //             return "Ngành nghề kinh doanh";
    //         }
    //         case CategoryType.ContactRole: {
    //             return "Vai trò liên hệ";
    //         }
    //         case CategoryType.ProjectStatus: {
    //             return "Trạng thái dự án";
    //         }
    //         case CategoryType.ProjectPhase: {
    //             return "Giai đoạn dự án";
    //         }
    //         case CategoryType.ProjectResources: {
    //             return "Nguồn dự án";
    //         }
    //         case CategoryType.ProjectCapital: {
    //             return "Nguồn vốn";
    //         }
    //         case CategoryType.ProjectSaleChannel: {
    //             return "Kênh bán hàng";
    //         }
    //         case CategoryType.TransactionType: {
    //             return "Loại nhật ký";
    //         }
    //         case CategoryType.CustomerStandardLiving: {
    //             return "Mức sống";
    //         }
    //         case CategoryType.ProjectType: {
    //             return "Loại dự án";
    //         }
    //         case CategoryType.PartnerType: {
    //             return "Loại đối tác";
    //         }
    //         case CategoryType.ConstructionArea: {
    //             return "Khu vực thi công";
    //         }
    //         case CategoryType.BOQResult: {
    //             return "Kết quả";
    //         }
    //         case CategoryType.PackageOutcome: {
    //             return "Phần trăm cơ hội";
    //         }
    //         case CategoryType.ProjectSaleProfileReason: {
    //             return "Lý do win/lost";
    //         }
    //         case CategoryType.PersonalCustomerBusinessCareer: {
    //             return "Nghề nghiệp";
    //         }
    //         case CategoryType.TruckPayload: {
    //             return "Trọng tải";
    //         }
    //         case CategoryType.CancelOrderReason: {
    //             return "Lý do hủy đơn hàng"
    //         }
    //         case CategoryType.ProjectSegment: {
    //             return "Segment";
    //         }
    //         case CategoryType.ProjectSpecStage: {
    //             return "SpecStage";
    //         }
    //         case CategoryType.CustomerKAC: {
    //             return "KAC";
    //         }
    //         case CategoryType.CustomerSalesStrategy: {
    //             return "Chiến lược bán hàng";
    //         }
    //         case CategoryType.CustomerFocusGroup: {
    //             return "Nhóm khách hàng cần tập trung";
    //         }
    //         case CategoryType.SalesStageGroup: {
    //             return "Nhóm sales stage";
    //         }
    //         case CategoryType.LeadSource: {
    //             return "Nguốn lead"
    //         }
    //         case CategoryType.Accountant: {
    //             return "Tài khoản kế toán";
    //         }
    //         case CategoryType.Branch: {
    //             return "Chi nhánh";
    //         }
    //         case CategoryType.CustomerClarification: {
    //             return "Phân cấp khách hàng";
    //         }
    //         case CategoryType.ExportImportType: {
    //             return "Danh mục nhập xuất";
    //         }
    //         case CategoryType.Inventory: {
    //             return "Kho";
    //         }
    //         case CategoryType.Revenue: {
    //             return "Doanh thu"
    //         }
    //
    //         case CategoryType.BusinessActivity: {
    //             return "Mô tả hoạt động"
    //         }
    //
    //         case CategoryType.ContractType: {
    //             return "Loại hợp đồng"
    //         }
    //
    //         case CategoryType.ReceiptType: {
    //             return "Loại phiếu thu";
    //         }
    //
    //         case CategoryType.PaymentType: {
    //             return "Loại phiếu chi";
    //         }
    //         default:
    //             return "Chọn loại";
    //     }
    //}
}

export class OptionItem {
    id: number;
    name: string;

    constructor(id: number) {
        this.id = id;
        switch (id) {
            case CategoryType.CustomerResources: {
                this.name = "Nguồn khách hàng";
                return;
            }
            case CategoryType.CustomerArea: {
                this.name = "Vùng khách hàng";
                return;
            }
            case CategoryType.CustomerClassify: {
                this.name = "Loại khách hàng";
                return;
            }
            case CategoryType.CustomerPotentialLevel: {
                this.name = "Hạng KH";
                return;
            }
            case CategoryType.CustomerCompanySize: {
                this.name = "Quy mô công ty";
                return;
            }
            case CategoryType.CustomerBusinessCareer: {
                this.name = "Ngành nghề kinh doanh";
                return;
            }
            case CategoryType.ContactRole: {
                this.name = "Vai trò liên hệ";
                return;
            }
            case CategoryType.ProjectStatus: {
                this.name = "Trạng thái dự án";
                return;
            }
            case CategoryType.ProjectPhase: {
                this.name = "Giai đoạn dự án";
                return;
            }
            case CategoryType.ProjectResources: {
                this.name = "Nguồn dự án";
                return;
            }
            case CategoryType.ProjectCapital: {
                this.name = "Nguồn vốn";
                return;
            }
            case CategoryType.ProjectSaleChannel: {
                this.name = "Kênh bán hàng";
                return;
            }
            case CategoryType.TransactionType: {
                this.name = "Loại nhật ký";
                return;
            }
            case CategoryType.CustomerStandardLiving: {
                this.name = "Mức sống";
                return;
            }
            case CategoryType.ProjectType: {
                this.name = "Loại dự án";
                return;
            }
            case CategoryType.PartnerType: {
                this.name = "Loại đối tác";
                return;
            }
            case CategoryType.ConstructionArea: {
                this.name = "Khu vực thi công";
                return;
            }
            case CategoryType.BOQResult: {
                this.name = "Kết quả";
                return;
            }
            case CategoryType.PackageOutcome: {
                this.name = "Phần trăm cơ hội";
                return;
            }
            case CategoryType.ProjectSaleProfileReason: {
                this.name = "Lý do win/lost";
                return;
            }
            case CategoryType.PersonalCustomerBusinessCareer: {
                this.name = "Nghề nghiệp";
                return;
            }
            case CategoryType.TruckPayload: {
                this.name = "Trọng tải";
                return;
            }
            case CategoryType.CancelOrderReason: {
                this.name = "Lý do hủy đơn hàng";
                return;
            }
            case CategoryType.ProjectSegment: {
                this.name = "Segment";
                return;
            }
            case CategoryType.ProjectSpecStage: {
                this.name = "SpecStage";
                return;
            }
            // case CategoryType.CustomerKAC: {
            //     this.name = "KAC";
            //     return;
            // }
            case CategoryType.CustomerSalesStrategy: {
                this.name = "Tình trạng khách hàng";
                return;
            }
            case CategoryType.CustomerFocusGroup: {
                this.name = "Nhóm khách hàng cần tập trung";
                return;
            }
            case CategoryType.SalesStageGroup: {
                this.name = "Nhóm sales stage";
                return;
            }
            case CategoryType.LeadSource: {
                this.name = "Nguốn lead";
                return;
            }
            case CategoryType.Accountant: {
                this.name = "Tài khoản kế toán";
                return;
            }
            case CategoryType.Branch: {
                this.name = "Showroom";
                return;
            }
            case CategoryType.CustomerClarification: {
                this.name = "Phân cấp khách hàng";
                return;
            }
            case CategoryType.ExportImportType: {
                this.name = "Danh mục nhập xuất";
                return;
            }
            case CategoryType.Inventory: {
                this.name = "Kho";
                return;
            }
            case CategoryType.Revenue: {
                this.name = "Doanh thu";
                return;
            }

            case CategoryType.BusinessActivity: {
                this.name = "Mô tả hoạt động";
                return;
            }

            case CategoryType.ContractType: {
                this.name = "Loại hợp đồng";
                return;
            }

            case CategoryType.ReceiptType: {
                this.name = "Loại phiếu thu";
                return;
            }

            case CategoryType.PaymentType: {
                this.name = "Loại phiếu chi";
                return;
            }
            default:
                this.name = "Chọn loại";
                return;
        }
    }
}
