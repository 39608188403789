import axios from 'axios';
import {Category} from '@/models/category/category';
import {ActionResult} from "@/models/ActionResult";
import {SearchResult} from "@/models/SearchResult";
import {CategoryType} from "@/models/category/CategoryType";
import {Suggestion} from "@/models/Suggestion";

export interface CategoryItem {
    id: number;
    name: string;
    normalizedName: string
}

export class CategoryService {
    url = `${process.env.VUE_APP_CORE_URL}api/v1/categories`;

    search(keyword: string, type: number | null, page: number = 1, pageSize: number = 20): Promise<SearchResult<Category>> {
        return axios.get(this.url, {
            params: {
                keyword: keyword,
                type: type,
                page: page,
                pageSize: pageSize
            }
        });
    }

    getTypes(): Promise<Array<CategoryItem>> {
        return axios.get(`${this.url}/get-types`);
    }

    insert(category: Category): Promise<ActionResult<string>> {
        return axios.post(this.url, category);
    }

    update(category: Category): Promise<ActionResult<string>> {
        return axios.post(`${this.url}/update`, category);
    }

    updateManager(manager: any): Promise<ActionResult<string>> {
        return axios.post(`${this.url}/update-manager`, manager);
    }

    delete(id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}`);
    }

    changeActive(id: string, isActive: boolean): Promise<ActionResult> {
        return axios.post(`${this.url}/change-active`, {
            id: id,
            isActive: isActive
        });
    }

    getByType(type: CategoryType): Promise<SearchResult<Category>> {
        return axios.get(`${this.url}/types/${type}`);
    }

    getByTypes(types: CategoryType[]): Promise<SearchResult<Category>> {
        return axios.post(`${this.url}/types`, types);
    }

    suggestions(keyword: string, type: number): Promise<SearchResult<Suggestion>> {
        return axios.get(`${this.url}/suggestion/${type}`, {
            params: {
                searchTerm: keyword
            }
        });
    }

}