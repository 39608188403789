











































import { Component, Vue, Watch } from "vue-property-decorator";
import ChannelRevenueReport from "@/views/dashboard/ChannelRevenueReport.vue";
import StaffRevenueReport from "@/views/dashboard/StaffRevenueReport.vue";
import ProductGroupRevenueReport from "@/views/dashboard/ProductGroupRevenueReport.vue";
import CustomerRevenueReport from "@/views/dashboard/CustomerRevenueReport.vue";
import TopProduct from "@/views/dashboard/TopProduct.vue";
import CallReport from "@/views/dashboard/CallReport.vue";
import CustomerConversionReport from "@/views/dashboard/CustomerConversionReport.vue";
import CustomerConversionReportByRange from "@/views/dashboard/CustomerConversionReportByRange.vue";
import axios from 'axios';

@Component({
    components: {
        ChannelRevenueReport,
        CustomerRevenueReport,
        ProductGroupRevenueReport,
        StaffRevenueReport,
        TopProduct,
        CallReport,
        CustomerConversionReport,
        CustomerConversionReportByRange
    }
})
export default class Dashboard extends Vue {
    mounted() {
    }
}
