









































































import {Component, Vue, Watch} from "vue-property-decorator";
import {RevenueReportService} from "@/views/dashboard/RevenueReportService";
import CategorySelect from "@/components/category/CategorySelect.vue";
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import {CategoryType} from "@/models/category/CategoryType";
import {CustomerGroupService} from "@/views/customer-group/CustomerGroupService";
import {TreeModel} from "@/components/tree/TreeModel";
import moment from 'moment';
import vSelect from 'vue-select';

@Component({
    components: {
        RevenueReport: () => import(/* webpackChunkName: "RevenueReport" */ "@/views/dashboard/RevenueReport.vue"),
        CategorySelect,
        CustomerGroupTreeDropdown,
        UserSuggestion,
        'v-select': vSelect,
    }
})
export default class StaffRevenueReport extends Vue {
    data = null;
    filterBy = 0;
    filterValue = 0;
    year = 0;
    isShowFilter = false;
    isLoading = false;
    categoryType = CategoryType;
    salesChannelIds = [];
    resources = [];
    listUserSelected = [];
    customerSalesChannels: any[] = [];
    listYear: number[] = [];
    listFilterBy = [{id: 0, name: 'Tuần'},
        {id: 1, name: 'Tháng'},
        {id: 2, name: 'Quý'},
        {id: 3, name: 'Năm'}];

    @Watch('filterBy')
    onFilterByChange(value: any) {
        this.filterValue = value === 0 ? moment().week() + 1
            : value === 1 ? moment().month() + 1
                : value === 2 ? moment().quarter()
                    : 0;
    }

    @Watch('isLoading')
    onLoadingChange(value: any) {
        if (value) {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: '#btnStaffRevenueSearch',
                scale: 0.45
            });
        } else {
            this.$vs.loading.close('#btnStaffRevenueSearch > .con-vs-loading');
        }
    }

    get filterValueLabel() {
        return this.filterBy === 0 ? 'Tuần'
            : this.filterBy === 1 ? 'Tháng'
                : this.filterBy === 2 ? 'Quý'
                    : 'Năm';
    }

    get listFilterValue() {
        let filterValues: any = [];
        const currentYear = new Date().getFullYear();
        switch (this.filterBy) {
            case 0:
                for (let i = 1; i <= 54; i++) {
                    filterValues.push(i);
                }
                return filterValues;
            case 1:
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            case 2:
                return [1, 2, 3, 4];
            case 3:
                for (let i = 2018; i <= currentYear; i++) {
                    filterValues.push(i);
                }
                return filterValues;
            default:
                return [];
        }
    }

    beforeMount() {
        this.renderYear();
        const weekday = moment().weekday();
        this.filterValue = weekday === 0 ? moment().weeks() - 1 : moment().weeks();
    }

    mounted() {
        this.search();
        this.getCustomerGroup();
    }

    onSalesChannelSelected(data: any) {

    }

    onSelectResource(data: any) {
        this.resources = data;
    }

    onUserSelected(result: any) {
        this.listUserSelected = result;
    }

    onSelectedUserRemoved(item: any) {
        const index = this.listUserSelected.findIndex((user: any) => {
            return user.id === item.id;
        });
        this.$delete(this.listUserSelected, index);
    }

    search() {
        if (this.filterBy == null || this.filterBy === undefined) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn tần xuất`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
            return;
        }
        if (this.filterValue == null || this.filterValue === undefined && this.filterBy != 3) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn ${this.filterValueLabel}`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
        } else {
            this.isLoading = true;
            new RevenueReportService()
                .getRevenueReportByStaff({
                    filterBy: this.filterBy,
                    filterValue: this.filterValue,
                    year: this.year,
                    resourceId: this.resources.map((resource: any) => {
                        return resource.id;
                    }),
                    salesChannelId: this.salesChannelIds,
                    managerIds: this.listUserSelected.map((user: any) => {
                        return user.id;
                    }),
                    managerIdNumbers: this.listUserSelected.map((user: any) => {
                        return user.data.idNumber;
                    })
                })
                .then((result: any) => {
                    this.isLoading = false;
                    const chart: any = this.$refs.staffRevenueChart;
                    chart.updateData(result);
                })
                .catch(() => this.isLoading = false);
        }
    }

    private getCustomerGroup() {
        const customerGroupService = new CustomerGroupService();
        customerGroupService.search()
            .then((result: TreeModel[]) => {
                this.customerSalesChannels = result;
            });
    }

    private renderYear() {
        const currentYear = new Date().getFullYear();
        this.year = currentYear;
        for (let i = 2018; i <= currentYear; i++) {
            this.listYear.push(i);
        }
    }
}
