
























import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import Suggestion from "@/components/suggestion/base-suggestion/Suggestion.vue";
import {
    SuggestionModel,
    SuggestionType
} from "@/components/suggestion/base-suggestion/SuggestionModel";
import {UserSuggestionModel} from './UserSuggestionModel';
import {UserService} from '../../../services/UserService';

@Component({
    components: {
        Suggestion,
        CustomerQuickInsert: () =>
            import(
                /*webpackChunkName: "CustomerQuickInsert"*/ "@/views/customer/CustomerQuickInsert.vue"
                )
    }
})
export default class UserSuggestion extends Vue {
    @Prop({default: SuggestionType.input}) type!: SuggestionType;
    @Prop({default: ""}) placeholder!: string; // popup | input
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: false}) showAddButton!: boolean;
    @Prop({default: false}) allowAddWhenNotExists!: boolean;
    @Prop({default: () => null}) value!: UserSuggestionModel;
    @Prop({default: () => []}) listSelected!: SuggestionModel[];

    @Ref('suggestionComponent') suggestionComponent!: any;

    searchTerm = "";
    listSuggestion: SuggestionModel[] = [];
    isLoading = false;
    private userService = new UserService();

    get notFoundText() {
        return this.$t("noCustomerFounded", [this.searchTerm]);
    }

    get addLabel() {
        const add = this.$t('Add');
        const customer = this.$t('Customer');
        return `${add} ${customer}`;
    }

    mounted() {
    }

    onSearch(searchTerm: string) {
        this.searchTerm = searchTerm;
        this.search();
    }

    onShown() {
        if (this.listSuggestion.length === 0) {
            this.search();
        }
    }

    show() {
        this.suggestionComponent.show();
    }

    private async search() {
        this.isLoading = true;
        try {
            const result = await this.userService.suggestion(
                this.searchTerm
            );
            this.isLoading = false;
            this.listSuggestion = result.items.map(
                (userSuggestion: UserSuggestionModel) => {
                    return new SuggestionModel(
                        userSuggestion.id,
                        userSuggestion.fullName,
                        userSuggestion.userName,
                        userSuggestion.avatar,
                        this.checkIsSelected(userSuggestion.id),
                        userSuggestion
                    )
                }
            );
        } catch (error) {
            this.listSuggestion = [];
        }
    }

    private checkIsSelected(id: string): boolean {
        const index = this.listSelected.findIndex(
            (item: SuggestionModel) => {
                return item.id === id;
            }
        );
        return index !== -1;
    }
}
