















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { CategoryType } from "@/models/category/CategoryType";
import { SearchResult } from "@/models/SearchResult";
import vSelect from 'vue-select';
import { CategoryService } from '@/store/category/CategoryService';
import { Category } from "@/models/category/category";
import { ActionResult } from "@/models/ActionResult";
import * as _ from 'lodash';
import Suggestion from "@/components/suggestion/base-suggestion/Suggestion.vue";
import { SuggestionModel } from "@/components/suggestion/base-suggestion/SuggestionModel";

@Component({
    components: {
        vSelect,
        Suggestion
    }
})
export default class CategorySelect extends Vue {
    @Prop() value!: any;
    @Prop() text!: any;
    @Prop() type!: CategoryType;
    @Prop({default: 'Không tim thấy kết quả'}) noFoundText!: string;
    @Prop({default: true}) allowAdd!: boolean;
    @Prop({default: ''}) placeholder!: boolean;
    @Prop({default: false}) isMultiple!: boolean;
    @Prop({default: () => []}) listSelected!: [];

    selectedText = '';
    isLoading = false;
    categoryService = new CategoryService();
    data: SuggestionModel<Category>[] = [];

    @Emit('select')
    emitSelect(categories: Category[]) {
        return categories;
    }

    mounted() {
    }

    onShown(isShow: boolean) {
        if (this.data.length === 0) {
            this.search('');
        }
    }

    onAdd(searchTerm: string) {
        if (!searchTerm || searchTerm.length === 0) {
            return;
        }
        const category = new Category('', searchTerm, '');
        category.type = this.type;
        this.categoryService.insert(category)
            .then((result: ActionResult<string>) => {
                if (result.code > 0) {
                    this.search(searchTerm);
                }
            });
    }

    onCategorySelect(data: SuggestionModel[]) {
        this.$emit('select', data.map((item: SuggestionModel) => {
            return item.data;
        }));
    }

    onSearch(searchTerm: string) {
        this.search(searchTerm);
    }

    // onSelect(suggestions: SuggestionModel<Category>[]) {
    //     if (!this.isMultiple) {
    //         const category = suggestions[0].data;
    //         if (category) {
    //             this.$emit('input', category.id);
    //             setTimeout(() => {
    //                 this.emitSelect([category]);
    //             }, 100);
    //         }
    //     } else {
    //         const listCategory = suggestions.map((suggestion: SuggestionModel<Category>) => {
    //             return suggestion.data;
    //         }) as Category[];
    //         setTimeout(() => {
    //             this.emitSelect(listCategory);
    //         }, 100);
    //     }
    // }

    searchDebounce: any = _.debounce((loading: any, search: string, vm: any) => {
        this.isLoading = true;
        this.categoryService.search(search, this.type)
            .then((result: SearchResult<Category>) => {
                this.isLoading = false;
                vm.data = result.items;
            })
            .catch(e => loading(false));
    }, 500);

    private search(searchTerm: string) {
        this.categoryService.search(searchTerm, this.type)
            .then((result: SearchResult<Category>) => {
                this.data = result.items.map((category: Category) => {
                    return new SuggestionModel(category.id, category.name, '', '', false, category);
                });
            });
    }
}
