











































































import { Component, Vue, Watch } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts/dist/vue-apexcharts";
import { ConversionService } from "@/views/dashboard/ConversionService";
import moment from 'moment';
import { CategoryType } from "@/models/category/CategoryType";
import { CustomerGroupService } from "@/views/customer-group/CustomerGroupService";
import { TreeModel } from "@/components/tree/TreeModel";
import CustomerGroupTreeDropdown from "@/components/CustomerGroupTreeDropdown.vue";
import CategorySelect from "@/components/category/CategorySelect.vue";
import UserSuggestion from "@/components/suggestion/user-suggestion/UserSuggestion.vue";
import vSelect from 'vue-select';

@Component({
    components: {
        apexchart: VueApexCharts,
        CategorySelect,
        CustomerGroupTreeDropdown,
        UserSuggestion,
        'v-select': vSelect,
    }
})
export default class CustomerConversionReport extends Vue {
    series: any = [{
        data: []
    }];

    filterBy = 0;
    filterValue = 0;
    year = 0;
    categoryType = CategoryType;
    salesChannelIds = [];
    resources = [];
    listUserSelected = [];
    customerSalesChannels: any[] = [];
    listYear: number[] = [];
    isShowFilter = false;
    isLoading = false;
    listFilterBy = [{id: 0, name: 'Tuần'},
        {id: 1, name: 'Tháng'},
        {id: 2, name: 'Quý'},
        {id: 3, name: 'Năm'}];

    chartOptions = {
        chart: {
            type: 'bar',
            height: 600
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    position: 'top',
                },
            }
        },
        colors: ["#F3B415", "#F27036", "#663F59", "#6A6E94", "#4E88B4", "#00A7C6", "#18D8D8", '#A9D794',
            '#46AF78', '#A93F55', '#8C5E58', '#2176FF', '#33A1FD', '#7A918D', '#BAFF29'
        ],
        dataLabels: {
            enabled: true,
            offsetX: -6,
            style: {
                fontSize: '12px',
                colors: ['#fff']
            }
        },
        stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            categories: ['Mới', 'Đã tương tác', 'Đã mua', 'Quay lại',
                'Quay lại từ kỳ trước'],
        },
    };

    @Watch('filterBy')
    onFilterByChange(value: any) {
        this.filterValue = value === 0 ? moment().week() + 1
            : value === 1 ? moment().month() + 1
                : value === 2 ? moment().quarter()
                    : 0;
    }

    @Watch('isLoading')
    onLoadingChange(value: any) {
        if (value) {
            this.$vs.loading({
                background: 'primary',
                color: '#fff',
                container: '#btnCustomerConversionSearch',
                scale: 0.45
            });
        } else {
            this.$vs.loading.close('#btnCustomerConversionSearch > .con-vs-loading');
        }
    }

    get filterValueLabel() {
        return this.filterBy === 0 ? 'Tuần'
            : this.filterBy === 1 ? 'Tháng'
                : this.filterBy === 2 ? 'Quý'
                    : 'Năm';
    }

    get listFilterValue() {
        let filterValues: any = [];
        const currentYear = new Date().getFullYear();
        switch (this.filterBy) {
            case 0:
                for (let i = 1; i <= 54; i++) {
                    filterValues.push(i);
                }
                return filterValues;
            case 1:
                return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
            case 2:
                return [1, 2, 3, 4];
            case 3:
                for (let i = 2018; i <= currentYear; i++) {
                    filterValues.push(i);
                }
                return filterValues;
            default:
                return [];
        }
    }

    beforeMount() {
        this.renderYear();
        const weekday = moment().weekday();
        this.filterValue = weekday === 0 ? moment().weeks() - 1 : moment().weeks();
    }

    mounted() {
        this.search();
        this.getCustomerGroup();
    }

    onSalesChannelSelected(data: any) {
        console.log(data);
    }

    onSelectResource(data: any) {
        this.resources = data;
    }

    onUserSelected(result: any) {
        this.listUserSelected = result;
    }

    onSelectedUserRemoved(item: any) {
        const index = this.listUserSelected.findIndex((user: any) => {
            return user.id === item.id;
        });
        this.$delete(this.listUserSelected, index);
    }

    search() {
        if (this.filterBy == null || this.filterBy === undefined) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn tần xuất`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
            return;
        }
        if (this.filterBy == null || this.filterBy === undefined) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn tần xuất`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
            return;
        }
        if (this.filterValue == null || this.filterValue === undefined) {
            this.$vs.notify({
                title: "Thông báo",
                text: `Vui lòng chọn ${this.filterValueLabel}`,
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
        } else {
            this.isLoading = true;
            new ConversionService().getCustomerConversion({
                filterBy: this.filterBy,
                filterValue: this.filterValue,
                year: this.year,
                resourceId: this.resources.map((resource: any) => {
                    return resource.id;
                }),
                salesChannelId: this.salesChannelIds,
                managerIds: this.listUserSelected.map((user: any) => {
                    return user.id;
                }),
                managerIdNumbers: this.listUserSelected.map((user: any) => {
                    return user.data.idNumber;
                })
            }).then((result: any) => {
                this.isLoading = false;
                if (result) {
                    const chart: any = this.$refs.conversionChart;
                    chart.updateSeries([{
                        data: [result.new, result.interacted, result.purchased, result.comeback, result.comebackFromPrevious]
                    }])
                }
            }).catch(() => this.isLoading = false);
        }
    }

    private getCustomerGroup() {
        const customerGroupService = new CustomerGroupService();
        customerGroupService.search()
            .then((result: TreeModel[]) => {
                this.customerSalesChannels = result;
            });
    }

    private renderYear() {
        const currentYear = new Date().getFullYear();
        this.year = currentYear;
        for (let i = 2018; i <= currentYear; i++) {
            this.listYear.push(i);
        }
    }
}
