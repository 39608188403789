import axios from 'axios';
import {ActionResult} from "@/models/ActionResult";
import {SearchResult} from "@/models/SearchResult";
import {TreeModel} from "@/components/tree/TreeModel";
import Tree from "@/components/tree/Tree.vue";

export class CustomerGroupService {
    url = `${process.env.VUE_APP_CUSTOMER_URL}api/v1/customer-groups`;

    insert(parentId: string, name: string): Promise<ActionResult> {
        return axios.post(this.url, {
            name,
            parentId
        });
    }

    update(id: string, parentId: string, name: string, concurrencyStamp: string): Promise<ActionResult> {
        return axios.put(`${this.url}/${id}`, {
            name,
            parentId,
            concurrencyStamp
        });
    }

    updateId(id: string, parentId: string, name: string, concurrencyStamp: string, idSaleIG: string, idReturnIG: string, erpCode: string, printTemplate: string): Promise<ActionResult> {
        return axios.put(`${this.url}/${id}/update`, {
            name,
            parentId,
            concurrencyStamp,
            idSaleIG,
            idReturnIG,
            erpCode,
            printTemplate
        });
    }

    delete(id: string): Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}`);
    }

    async search(searchTerm?: string): Promise<TreeModel[]> {
        const response: SearchResult<TreeModel> = await axios.get(this.url, {
            params: searchTerm
        }) as SearchResult<TreeModel>;
        try {
            return response.items;
        } catch (e) {
            return [] as TreeModel[];
        }
    }

    deletes(deleteIds: string[]): Promise<ActionResult[]> {
        return axios.post(`${this.url}/deletes`, deleteIds);
    }

    updateViewStatus(id: any, isView: boolean): Promise<ActionResult> {
        return axios.get(`${this.url}/${id}/views`, {
            params: {
                isView
            }
        });
    }

    updateApproveStatus(id: any, isApprove: boolean): Promise<ActionResult> {
        return axios.get(`${this.url}/${id}/approve`, {
            params: {
                isApprove
            }
        });
    }
}