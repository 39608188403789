import axios from 'axios';
import {DashboardReportFilterRequest} from "@/views/dashboard/DashboardReportFilterRequest";

export class ConversionService {
    getCustomerConversion(request: DashboardReportFilterRequest) {
        return axios.post(`${process.env.VUE_APP_GATEWAY_URL}customer/api/v1/customer-reports/conversion`, request);
    }

    getCustomerConversionByRange(request: DashboardReportFilterRequest) {
        return axios.post(`${process.env.VUE_APP_GATEWAY_URL}customer/api/v1/customer-reports/conversion/range`, request);
    }
}
